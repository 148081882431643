import { useEffect, useState } from 'react'
import styles from './SliderLanding.module.scss'
import Section, { SECTION_VARIANTS } from 'components/Section'
import classNames from 'classnames'
import { useMemoizedContentGetter } from 'utils/contentful'
import { Typography, LinkButton } from 'components/Contentful'
import { Slider } from '@achieve/ascend'
import { IconListItem } from 'components/IconListItem'

const SliderLanding = ({ content }) => {
  const memoizedContent = useMemoizedContentGetter(content, ['title', 'cardList', 'sliderConfig'])
  const { title, cardList, sliderConfig } = memoizedContent
  const [sliderValue, setSliderValue] = useState(0)
  const dollarUSLocale = Intl.NumberFormat('en-US')
  const [ctaHref, setCtaHref] = useState('')
  useEffect(() => {
    setSliderValue(
      sliderConfig?.jsonContent?.slider?.defaultValue
        ? sliderConfig?.jsonContent?.slider?.defaultValue
        : sliderConfig?.jsonContent?.slider?.max / 2
    )
  }, [sliderConfig])
  useEffect(() => {
    const queryParameter = sliderConfig?.jsonContent?.queryParameter || 'loan_amount'
    setCtaHref(`${sliderConfig?.jsonContent?.ctaHref}&${queryParameter}=${sliderValue}`)
  }, [sliderConfig, sliderValue])
  function handleChange(e) {
    setSliderValue(e.target.value)
  }

  return (
    <Section
      className={classNames(styles['section'], {
        sectionVariant: SECTION_VARIANTS.achieveHighlightBlue,
        sectionStyle: null,
        layoutImage: 'responsive',
      })}
      childContainerClassName={styles['section-inner']}
      data-testid="slider-landing-section"
      variant={SECTION_VARIANTS.achieveHighlightBlue}
    >
      <div className={styles['item-container']}>
        <Typography
          content={title?.textContent}
          variant="displayLg"
          mobileVariant="headingMd"
          breakpoint="md"
          responsive={true}
          fontWeight="bold"
          className={styles['title']}
        />
      </div>
      {cardList?.iconCardItemCollection?.items?.length > 0 && (
        <div className={styles['item-container']}>
          <ul className={styles['pd-item-list']}>
            {cardList?.iconCardItemCollection?.items.map((icon, index) => {
              return (
                <IconListItem
                  key={`icon-list-item-${index}`}
                  iconColor={icon?.iconColor}
                  iconName={icon?.iconName}
                  itemText={icon?.description}
                  classNameText={styles['pd-item-list-text']}
                  variant="bodySm"
                  iconSize={20}
                />
              )
            })}
          </ul>
        </div>
      )}
      <div className={styles['item-container']}>
        <div className={styles['slider-tool-wrapper']}>
          <Typography
            content={sliderConfig?.jsonContent?.statsTitle}
            variant="displaySm"
            fontWeight="medium"
            data-testid="slider-title"
            className={styles['slider-title']}
          />
          <Typography
            content={`$${dollarUSLocale.format(sliderValue)}${
              sliderValue === sliderConfig?.jsonContent?.slider?.max &&
              sliderConfig?.jsonContent?.slider?.maxPlus
                ? '+'
                : ''
            }`}
            variant="displaySm"
            fontWeight="bold"
            className={styles['slider-tool-value']}
            data-testid="slider-dollar-value"
          />
          <Slider
            aria-label="dollar-amount-selection-slider"
            className={styles['slider-tool-slider']}
            classes={{
              mark: styles['marks'],
            }}
            data-testid="slider-input"
            value={sliderValue}
            step={sliderConfig?.jsonContent?.slider?.step}
            min={sliderConfig?.jsonContent?.slider?.min}
            max={sliderConfig?.jsonContent?.slider?.max}
            marks={[
              {
                value: sliderConfig?.jsonContent?.slider?.min,
                label: (
                  <Typography
                    content={`$${dollarUSLocale.format(sliderConfig?.jsonContent?.slider?.min)}`}
                    variant="bodyXs"
                    className={styles['slider-tool-min-mark']}
                    data-testid="slider-min"
                  />
                ),
              },
              {
                value: sliderConfig?.jsonContent?.slider?.max,
                label: (
                  <Typography
                    content={`$${dollarUSLocale.format(sliderConfig?.jsonContent?.slider?.max)}${
                      sliderConfig?.jsonContent?.slider?.maxPlus ? '+' : ''
                    }`}
                    variant="bodyXs"
                    className={styles['slider-tool-max-mark']}
                    data-testid="slider-max"
                  />
                ),
              },
            ]}
            onChange={handleChange}
          />
          <LinkButton
            track={{
              ...{
                list_name: 'Slider landing CTA click',
                click_id: sliderConfig?.jsonContent?.ctaText,
                click_text: sliderConfig?.jsonContent?.ctaText,
                form_ss_amount: sliderValue,
                click_url: ctaHref,
              },
            }}
            content={{
              linkHref: ctaHref,
              linkText: sliderConfig?.jsonContent?.ctaText,
            }}
            typographicOptions={{ variant: 'bodyLg', fontWeight: 'bold' }}
            variant="contained"
            color="primary"
            className={styles['slider-tool-cta']}
            fullWidth
          />
        </div>
      </div>
    </Section>
  )
}

export default SliderLanding
